<template>
  <b-card>
    <load-profile></load-profile>
    <div v-if="data.id">
      <b-card-title title-tag="h2" class="font-weight-bold mb-1">
        {{ $t("field.profile") }}
      </b-card-title>

      <b-row class="mt-2">
        <b-col cols="auto" class="text-center">
          <b-avatar
            size="80"
            variant="light-primary"
            :src="data.image"
            :text="avatarText(data.name)"
          >
            <feather-icon v-if="!data.name" icon="UserIcon" size="22" />
          </b-avatar>
          <h5 class="mt-1 mb-0 text-capitalize profile-name">
            {{ data.name }}
          </h5>
          <small>{{ data.roles.join(", ") }}</small>
        </b-col>
      </b-row>

      <p class="mt-2">
        <feather-icon icon="CalendarIcon" />
        {{ $t("field.joint") }}
        {{ data.createdAt | formatDate("LL") }}
      </p>

      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mt-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="
              $router.push({ name: `update-${route}`, params: { id: data.id } })
            "
            v-if="
              $can('update', resource) &&
              !data.isDefault &&
              data.id != userData.id
            "
          >
            <feather-icon icon="EditIcon" /> {{ $t("button.update") }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BButton,
  BAvatar,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Repository from "@/repositories/RepositoryFactory";
import NButtonLoading from "@/components/NButtonLoading";
import { avatarText } from "@core/utils/filter";

const UserRepository = Repository.get("user");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BButton,
    BAvatar,
    BImg,
    NButtonLoading,
  },
  directives: {
    Ripple,
  },
  computed: {
    userData: function () {
      return this.$store.state.profile?.username
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
  },
  data() {
    return {
      data: {},
      loading: false,

      avatarText,
    };
  },
  created() {
    this.show();
  },
  methods: {
    show() {
      UserRepository.detail(this.$route.params.id).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.data = {
            ...data,
          };
        }
      });
    },
  },
  setup() {
    const resource = "agent-user";
    const route = "user";

    return { resource, route };
  },
};
</script>
